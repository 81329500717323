<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control is-expanded">
              <multiselect v-model="selectedEntityShortName"
                placeholder="Select An Entity Name"
                track-by="entityShortName"
                label="entityShortName"
                :options="entityNames"
                :searchable="true"
                :show-labels="false"
                :allow-empty="true"
                @select="selectEntityShortName"
                @remove="removeEntityShortName"
                @search-change="getEntityNameDropdownFilter">
                <span class="has-text-danger"
                  slot="noResult">Entity Name not found.</span>
              </multiselect>
            </div>
          </div>
          <div class="field">
            <div class="control is-expanded">
              <multiselect v-model="selectedFieldName"
                track-by="columnName"
                label="columnName"
                placeholder="Select A Field Name"
                :options="fieldNames"
                :searchable="true"
                :show-labels="false"
                :allow-empty="true"
                @select="selectFieldName"
                @remove="removeFieldName"
                @search-change="getFieldNameDropdownFilter">
                <span class="has-text-danger"
                  slot="noResult">Field name not found.</span>
              </multiselect>
            </div>
          </div>
          <div class="field">
            <div class="control is-expanded">
              <multiselect v-model="selectedPerformedBy"
                placeholder="Select A User Name"
                track-by="userName"
                label="userName"
                :options="users"
                :searchable="true"
                :show-labels="false"
                :allow-empty="true"
                @select="selectPerformedBy"
                @remove="removePerformedBy"
                @search-change="getPerformedByDropdownFilter">
                <span class="has-text-danger"
                  slot="noResult">User not found.</span>
              </multiselect>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div class="select is-fullwidth"
                :class="[`select-${$user.info.theme}`]">
                <select v-model="value.operation">
                  <option value="">-- Operation --</option>
                  <option value="UPDATE">UPDATE</option>
                  <option value="INSERT">INSERT</option>
                  <option value="DELETE">DELETE</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <v-date-picker v-model="selectedFromDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      :class="[`input-${$user.info.theme}`]"
                      placeholder="Date From..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field">
            <v-date-picker v-model="selectedToDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      :class="[`input-${$user.info.theme}`]"
                      placeholder="Date To..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import { mask } from 'vue-the-mask'
import _cloneDeep from 'lodash/cloneDeep'
import AuditTrailService from './AuditTrailService'
import Multiselect from 'vue-multiselect'
import _debounce from 'lodash.debounce'

export default {
  name: 'AuditTrailListSideMenu',
  inject: ['$vv'],
  components: {
    AppSideMenu,
    Multiselect
  },
  directives: {
    mask
  },
  mixins: [],
  props: {
    filter: null,
    value: null
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      stateList: null,
      selectedFromDate: null,
      selectedToDate: null,
      selectedEntityShortName: null,
      selectedFieldName: null,
      selectedPerformedBy: null,
      entityNames: [],
      fieldNames: [],
      users: [],
      isEntityShortNameLoading: false,
      isFleidNameLoading: false,
      isPerformedByLoading: false,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    selectedFromDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.dateFrom = this.selectedFromDate.toISOString()
      } else {
        this.innerValue.dateFrom = ''
      }
    },
    selectedToDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.dateTo = this.selectedToDate.toISOString()
      } else {
        this.innerValue.dateTo = ''
      }
    },
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    value: {
      handler: function (newVal, oldVal) {
        this.innerValue = this.value
      },
      deep: true
    }
  },
  async created() {
    if (this.innerValue.dateFrom) {
      this.selectedFromDate = new Date(this.innerValue.dateFrom)
    }
    if (this.innerValue.dateTo) {
      this.selectedToDate = new Date(this.innerValue.dateTo)
    }
    this.getEntityNameDropdown()
    this.getPerformedByDropdown()
    this.getFieldNameDropdown()
  },
  mounted() {},
  methods: {
    onFilterClick() {
      this.$emit('filter')
    },
    onResetClick() {
      this.selectedFromDate = null
      this.selectedToDate = null
      this.selectedEntityShortName = null
      this.fieldNames = []
      this.selectedFieldName = null
      this.selectedPerformedBy = null
      this.$emit('reset')
    },
    async selectEntityShortName(selected) {
      this.innerValue.entityShortName = selected.entityShortName
      this.getFieldNameDropdown('')
    },
    removeEntityShortName() {
      this.innerValue.entityShortName = null
      this.innerValue.columnName = null
      this.selectedFieldName = null
      this.fieldNames = []
      this.getEntityNameDropdown()
    },
    selectFieldName(selected) {
      this.innerValue.columnName = selected.columnName
    },
    removeFieldName(removed) {
      this.innerValue.columnName = ''
    },
    selectPerformedBy(selected) {
      this.userName = selected.userName
      this.userId = selected.userId
      this.innerValue.userId = selected.userId
      this.innerValue.userName = selected.userName
    },
    removePerformedBy(removed) {
      this.innerValue.userId = ''
      this.innerValue.userName = ''
    },
    async getEntityNameDropdown() {
      this.isEntityShortNameLoading = true
      this.entityNames = await AuditTrailService.getEntityNameDropdownProto()
      this.entityNames.unshift({ entityShortName: 'All Entity Names', entityNames: '' })
      this.selectedEntityShortName = this.entityNames.find((c) => c.entityShortName === this.innerValue.entityShortName)
      this.isEntityShortNameLoading = false
      return this.entityNames
    },
    async getPerformedByDropdown() {
      this.isPerformedByLoading = true
      this.users = await AuditTrailService.getPerformedByDropdownProto()
      this.users.unshift({ userName: 'All users', userId: '' })
      this.selectedPerformedBy = this.users.find((u) => u.userName === this.innerValue.userName)
      this.isPerformedByLoading = false
      return this.users
    },
    async getFieldNameDropdown() {
      if (this.innerValue.entityShortName) {
        this.isFleidNameLoading = true
        this.fieldNames = await AuditTrailService.getFieldNameDropdown(this.innerValue.entityShortName)
        this.fieldNames.unshift({ columnName: 'All Fields', fieldNames: '' })
        this.selectedFieldName = this.fieldNames.find((f) => f.columnName === this.innerValue.columnName)
        this.isFleidNameLoading = false
        return this.fieldNames
      }
    },
    getEntityNameDropdownFilter: _debounce(async function (query) {
      this.getEntityNameDropdown()
    }, 500),
    getPerformedByDropdownFilter: _debounce(async function (query) {
      this.getPerformedByDropdown()
    }, 500),
    getFieldNameDropdownFilter: _debounce(async function (query) {
      this.getFieldNameDropdown()
    }, 500)
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';
input.uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
